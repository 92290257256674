export const noop = () => {};

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

export const BASE_URL = '/api/charity-ball/';

export const REGEX_EMAIL = /.*@.*\..*/;

export const defaultUser = {
    username: 'guest',
    role: '__guest_role__'
  };

export const TICKET_PRICE = 75;

export const isTouchScreen = (window.matchMedia("(any-pointer: coarse)").matches) ? true : false;
