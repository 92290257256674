import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Heading, Box, Text, Input, keyframes, useBreakpointValue } from "@chakra-ui/react";

import { Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react'

//import { Input as ChakraInput } from "@chakra-ui/react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import { useToastHook } from "./Toast.jsx";
//import { SocialButton } from "./Footer.jsx";


import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';

import { mongoTest } from "../util/mongoTest.js";

//import { restRequest } from "../util/utils.js";
//import { GET, BASE_URL } from "../util/constants.js";


const dropIn = keyframes({
    'from': {
        opacity: 0,
        transform: 'translateY(-100px)',
        },
    'to': {
        opacity: 1,
        transform: 'translate(0px)',
    }
});

//fontSize={useBreakpointValue({base: '1.6rem', sm: '1.6rem', md: '2.0rem'})}

export const SlideDownHeading = ( props ) => {
    return (
       <Box overflow='hidden'>
           <Heading
                as={'div'}
                m={2} p={2}
                fontSize={'2.0rem'}
                color='pink.600'
                animation={`${dropIn} .5s ease 400ms backwards`}
                fontFamily={'Oooh Baby'}
                width={'auto'}
                height={'auto'}
                {...props}
            >
                {props.children}
            </Heading>
       </Box> 
    )
}

const FadeInFromLeft = keyframes({
    'from': {
        opacity: 0,
        transform: 'translateX(-20px)',
        },
    'to': {
        opacity: 1,
        transform: 'translate(0px)',
    }
});


export const FadeInBoxLeft = ( props ) => {
    return (
       <Box overflow='hidden'>
           <Box
                width='100%'
                animation={`${FadeInFromLeft} .7s ease 600ms backwards`}
                {...props}
            >
                {props.children}
            </Box>
       </Box> 
    )
};

const FadeInFromRight = keyframes({
    'from': {
        opacity: 0,
        transform: 'translateX(20px)',
        },
    'to': {
        opacity: 1,
        transform: 'translate(0px)',
    }
});


export const FadeInBoxRight = ( props ) => {
    return (
       <Box overflow='hidden'>
           <Box
                width='100%'
                animation={`${FadeInFromRight} .8s ease 800ms backwards`}
                {...props}
            >
                {props.children}
            </Box>
       </Box> 
    )
};

const FaIconStyled = styled(FontAwesomeIcon)`
  background: blackAlpha.100;
  width: 32px;
  height: 32x;
  paddring-right: 20px;
  transition: background 0.3s ease;
  rounded: full;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  &::hover {
    background: blackAlpha.400;
  }
`;

export function StandardTable ( { data, structure, name=false } ) {


    //const navigate = useNavigate();
  
    const [reportData, setReportData] = useState(data);
    const [filteredData, setFilteredData] = useState([]);
    const [dataLength, setDataLength] = useState(0);
    
    const [reverse, setReverse] = useState(-1);
  
    const fields = Object.keys(structure);
    const [sortOn, setSortOn] = useState('');
  
    const allFields = Object.keys(data);
  
    let tempFilter = {}
    for (const key of fields) { tempFilter[key] = '';}
  
    if (name && `${name}-filter` in window.sessionStorage) {
      tempFilter = ( JSON.parse( window.sessionStorage.getItem(`${name}-filter`) ));
    }
  
    const [myFilter, setMyFilter] = useState(tempFilter);
  
  
    /* 
    const fmtC = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    });
   */
  
    /*
    useEffect(
      () => {
        setReportData(data);
      },
      [data]
    );
    */
    
    const handleChange = (e) => {
      const tempFilter = {...myFilter, [e.target.name]: e.target.value};
      if (name) {
        window.sessionStorage.setItem(`${name}-filter`, JSON.stringify(tempFilter))
      }
      setMyFilter(tempFilter);
    }
  
    const handleSort = (myField) => {
      let savedSort = [['', 1], ['', 1], ['', 1]]
      if (name && name in window.sessionStorage) {
        savedSort = JSON.parse(window.sessionStorage.getItem(name));
      }
      if (sortOn === myField) {
        savedSort = [[savedSort[0][0], reverse * -1], savedSort[1], savedSort[2]]
        setReverse(reverse * -1);
      } else {
        setSortOn(myField)
        if (savedSort[1][0] === myField) {
          savedSort = [[myField, reverse], savedSort[0], savedSort[2]]
        } else if (savedSort[2][0] === myField) {
          savedSort = [[myField, reverse], savedSort[0], savedSort[1]]
        } else {
          savedSort = [[myField, reverse], savedSort[0], savedSort[1]]
        }
      }
      if (name) {
        window.sessionStorage.setItem(name, JSON.stringify(savedSort))
      }
    }
  
    useEffect(
      () => {
        if (name && name in window.sessionStorage) {
          const savedSort = JSON.parse( window.sessionStorage.getItem(name) );
  
          if (savedSort[2][0] !== '') {
            data.sort( (a, b) => { return compare(a[savedSort[2][0]], b[savedSort[2][0]], savedSort[2][1]); })
          };
  
          if (savedSort[1][0] !== '') {
            data.sort( (a, b) => { return compare(a[savedSort[1][0]], b[savedSort[1][0]], savedSort[1][1]); })
          };
  
          if (savedSort[0][0] !== '') {
            data.sort( (a, b) => { return compare(a[savedSort[0][0]], b[savedSort[0][0]], savedSort[0][1]); })
          };
        };
        setReportData(data);        
      },
      [data]
    );
  
    useEffect(
      () => {
        const temp = [...reportData].sort( (a, b) => {
          return compare(a[sortOn], b[sortOn], reverse);
        });
        setReportData(temp);
      },
      [sortOn, reverse]
    );
  
    const compare = (first, second, ascDesc) => {
      let nameA = '';
      let nameB = '';
      if (typeof(first) === 'string') {
         nameA = first.toUpperCase();
      } else {
         nameA = first
      }
      if (typeof(second) === 'string') {
         nameB = second.toUpperCase();
      } else {
         nameB = second
      }
      if (nameA < nameB) {
        return ascDesc;
      }
      if (nameA > nameB) {
        return (ascDesc * -1);
      }
      return 0;
    };
  
    useEffect(
      () => {
        const temp = mongoTest(reportData, myFilter);
        setFilteredData(temp);
        setDataLength(temp.length);
      },
      [reportData, myFilter]
    );
  
  
    const buildLink = (data, field) => {
      let path = `${structure[field][1]}`;
      structure[field].map ( (value, index) => {
        if ( value !== '' & index > 1 ) {
          path += `/${data[value]}`
        }
      })
      return (path)
    }
  
    // https://theroadtoenterprise.com/blog/how-to-download-csv-and-json-files-in-react
  
    const downloadFile = ({ data, fileName, fileType }) => {
      const blob = new Blob([data], { type: fileType })
    
      const a = document.createElement('a')
      a.download = fileName
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }
  
    const exportToCsv = e => {
      e.preventDefault()
  
      if (data.length === 0) return;
  
      const allFields = Object.keys(data[0]);
    
      // The headers for each column ar our fields
  
      let reportCsv = data.reduce( (acc, item) => {
        const temp = [];
        //for (const key of fields) { temp.push(item[key])};
        for (const key of allFields) { temp.push(item[key])};
        acc.push(temp.join(','));
        return acc
      }, [])
    
      downloadFile({
        //data: [fields.join(','), ...reportCsv].join('\n'),
        data: [allFields.join(','), ...reportCsv].join('\n'),
        fileName: 'report.csv',
        fileType: 'text/csv',
      })
    }
  
  
    //return (
    //  <p>WE GOT HERE</p>
    //)
  
    return (
      <React.Fragment>
  
        <Box display='block' width='100%' height={8}>
          <Text float='left' pl={4} fontSize={['.7rem', '.8rem']}>{dataLength} rows returned</Text>
          <Box float='right'>
            <FaIconStyled onClick={exportToCsv} icon={ faFileCsv } />
          </Box>
        </Box>
  
        <TableContainer w='100%'>
            <Table size='sm' variant='striped' colorScheme='pink'>
            <TableCaption>all booking data</TableCaption>
          <Thead>
            <Tr>
              {
                fields.map( (field, index) => {
                  return (
                    <Th key={index} onClick={ () => handleSort(field)}>{structure[field][0]} {(field === sortOn) ? '*': ''}</Th>
                  )
                })
              }
            </Tr>
  
  
          <Tr>
            {
              fields.map( (field, index) => {
                return (
                  <Td key={index}><Input  fontSize='0.7rem' height='1.1rem' type='text' onChange={handleChange} name={field} value={myFilter[field]}/></Td>
                )
              })
            }
          </Tr>
          </Thead>
          <Tbody>
          {
            filteredData.map((data, index)=>{
              return (
                <Tr key={index}>
                  {
                    fields.map( (field, index) => {
                      return (
                        <Td key={index} fontSize='0.75rem'>
                          { structure[field][1] !== ''
                          ?
                            <Link to={ buildLink(data, field) } style={{all: 'unset'}}>
                              {data[field]}
                            </Link>
                          :
                            data[field]
                          }
                        </Td>
                      )
                    })
                  }
                </Tr>
              )
            })
          }
          </Tbody>
        </Table>
        </TableContainer>
      </React.Fragment>
    )
  };