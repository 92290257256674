function eq(a, b) {
	return (a === b);
};

function ne(a, b) {
	return (a !== b);
};

function lt(a, b) {
	return (a < b);
};

function gt(a, b) {
	return (a > b);
};

function le(a, b) {
	return (a <= b);
};

function ge(a, b) {
	return (a >= b);
};

function in_(a, b) {
	return ( b.includes(a) );
};

function nin_(a, b) {
	return ( ! in_(a, b) );
};

const op = {
  "$eq": eq, "$ne": ne,
  "$lt": lt, "$gt": gt,
  "$le": le, "$ge": ge,
  "$in": in_, "$nin": nin_
};

const myFilter = {
  a: 'hell',
  b: {$lt: 10},
  c: '',
  d: 'eat'
};

const testData = [
	{ a: 'hello', b: '', c: '', d: 'elephant' },	//no match
  { a: 'hello', b: 4, c: '', d: 'great' },	    //MATCH
  { a: 'bye', b: '', c: '', d: 'elephant' },		//no match
  { a: 'bye', b: '', c: '', d: 'great' },				//no match
  { a: 'spurs', b: '', c: '', d: 'elephant' },	//no match
  { a: 'spurs', b: '', c: '', d: 'elephant' },	//no match
  { a: 'my hell', b: 3, c: '', d: 'sweat' },		//MATCH
];



function testThis(obj, key, queryItem){
  if (typeof queryItem === 'object'){
    // this is an object
    const k = Object.keys(queryItem)[0];
    return (op[k](obj[key], queryItem[k]))
  } else if (typeof obj[key] === 'string' ) {
    //this is a string
    try {
      const queryItemAsRegExp = new RegExp(queryItem, "i");
      //console.log("matching regex");
      return ( queryItemAsRegExp.test(obj[key]) );
    } catch(err) {
      //console.log("matching full string");
      return ( queryItem === obj[key] );
    };
  } else {
    //this is a number
    //console.log("matching number");
  	return ( queryItem === obj[key] );
  };
};


function andSection(obj, queryDict) {
	let result = true;
  for (const [key, value] of Object.entries(queryDict)) {
  if (key === "$or") {
  	result = orSection(obj, queryDict[key])
  } else {
  	result =testThis(obj, key, queryDict[key])
  };
  
  if (result === false) {
  	return false;
  }
};
  return true
};


function orSection(obj, queryList) {
	let result = false;
  for (const [key, value] of Object.entries(queryList)) {
  	result = andSection(obj, key)
  };
  if (result) {
  	return true;
  };
  return false;
};

export function mongoTest (testData, myFilter) {

  const newFilter = {};
  for (const [key, value] of Object.entries(myFilter)) {
      if (value !== '')  {
        newFilter[key] = value;
      };
  };

  const results = [];
  testData.forEach(item => {
    if (andSection(item, newFilter)) {
  	  results.push(item)
    }
  });
  return (results);
};