import React from 'react';
import GlobalStyle from './theme/globalStyles.js';
import { ChakraProvider, Image, theme, Box, extendTheme, keyframes,
  Text, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
//import { Link, VStack, Grid } from '@chakra-ui/react';
import './theme/style.css'

import { Routes, Route, Outlet, useNavigate, Link  } from "react-router-dom";
//import { ColorModeSwitcher } from './ColorModeSwitcher';

import { useToastHook } from "./components/Toast.jsx";

import flyer from './img/CharityBall.png';
import wideFlyer from './img/LandscapePoster.png';
//import { Logo } from './Logo';

import { defaultUser, POST, BASE_URL } from "./util/constants.js";
import { FadeInBoxLeft, FadeInBoxRight } from './components/Common.jsx';

//import NavBar from './components/NavBar.jsx';
import Band from './components/Band.jsx';
import Footer from './components/Footer.jsx';
import Faqs from './components/Faqs.jsx';
import MyStory from './components/MyStory.jsx';
import Raffle from './components/Raffle.jsx';
import Sponsors from './components/Sponsors.jsx';
import Tickets from './components/Tickets.jsx';
import Schedule from './components/Schedule.jsx';
import Logon from './components/Logon.jsx';
import Report from './components/Report.jsx';
import HomeOnBackground from './components/Home.jsx';
import { Menu } from './components/Home.jsx';
import WithSubnavigation from './components/NavBar2.jsx';


function App() {

  const [state, newToast] = useToastHook();
  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = React.useState(defaultUser)

  const handleSignout = () => {

     
    const fetchData = {
      method: POST,
      headers: new Headers({
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json'
  
      })
    }
  
    fetch(`${BASE_URL}logout/`, fetchData)
      .then( response=>{
        return ( response.json() )
      })
      .then( data=>{
        newToast({ message: `${currentUser.username} signed out`, status: "success" });
        setCurrentUser(defaultUser);
        window.sessionStorage.removeItem('accessToken');
        navigate('/home')
      })
      .catch((error) => {
        console.log("sign-out", error);
      })
  
  };

  const breakpoints = {
    base: "0px",  //0px
    sm: "500px",  //320px
    md: "700px",  //768px
    lg: "900px",  //992px
    xl: "1200px", //1280px
    "2xl": "1536px",  //1536px
  }

  const customTheme = extendTheme({breakpoints});

  return (
    <ChakraProvider theme={customTheme} >
      <GlobalStyle />
      <Routes>
        <Route path="/" element={ <Layout/> } >
          <Route path='' element={<Home />} />
          <Route path='home' element={<Home />} />
          <Route path='menu' element={<Menu />} />
          <Route path='test' element={<HomeOnBackground />} />
          <Route path='story' element={<MyStory/>} />
          <Route path='sponsors' element={<Sponsors/>} />
          <Route path='raffle' element={<Raffle/>} />
          <Route path='tickets' element={<Tickets/>} />
          <Route path='faq' element={<Faqs/>} />
          <Route path='band' element={<Band/>} />
          <Route path='schedule' element={<Schedule/>} />
          <Route path='logon' element={<Logon />} />
          <Route path='bookingreport' element={<Report  />} />
          <Route path='*' element={<PageNotFound/>} />
        </Route>
      </Routes>
    </ChakraProvider>
  );

}

const Layout = ( props ) => {
  return (
    <Grid
      minHeight={'100vh'}
      gridTemplateRows="auto 1fr auto"
      gridTemplateAreas={`
        "header"
        "main"
        "footer"`
      }
    >
      <GridItem width='100vw' area={'header'}>
        <WithSubnavigation />
      </GridItem>

      <GridItem area={'main'} justifySelf={useBreakpointValue({ base: 'start', md: 'center' })} >
        <Outlet  />
      </GridItem>

      <GridItem area={'footer'} width='100vw' >
        <Footer/>
      </GridItem>
    </Grid>
  )
};

 
  
const Home = (props) => {
  return (
    <Box p={6} m={4} pl={[2,8]} maxW={useBreakpointValue({ base: 'xl', md: '3xl' })}>
      <FadeInBoxRight>
      <Image src={useBreakpointValue({ base: flyer, md: wideFlyer })} alt='Flyer'/>
      </FadeInBoxRight>
      <FadeInBoxLeft>
      <Box pt={4}>
        Drinks receptions starts at: <Box className='blinker blink-text' display={'inline-block'} fontFamily='Oooh Baby' color='pink.500'> 6:30pm</Box>
      </Box>
      <Box py={1}>
        Carriages home at: <Box className='blink-text' display={'inline-block'} fontFamily='Oooh Baby' color='pink.500'> midnight</Box>
      </Box>
      <Box py={4} _hover={{color: 'pink.500'}}>
        <Link to='/tickets'>Click <Box display={'inline-block'} fontFamily='Oooh Baby' fontSize={'1.4rem'}>here</Box> for tickets</Link>
      </Box>
      </FadeInBoxLeft>
    </Box>
  )
};

const PageNotFound = (props) => {
  return (
    <Text {...props}>
      Page not found.
    </Text>
  )
};

const Blank = (props) => {
  return (
    <Text {...props}>
      Under Construction.
    </Text>
  )
};

export default App;

/*
  return (
    <ChakraProvider theme={theme} >
        <Grid
          height={'100vh'}
          alignContent="center"
          justifyContent="center"
          gridTemplateRows="auto 1fr auto"
          border='5px solid green'
        >
          <WithSubnavigation currentUser={currentUser} handleSignout={handleSignout} />
          <Routes>
            <Route path="/" element={ <Outlet /> } >
              <Route path='home' element={<Home />} />
              <Route path='story' element={<MyStory/>} />
              <Route path='sponsors' element={<Sponsors/>} />
              <Route path='raffle' element={<Raffle/>} />
              <Route path='register' element={<Register/>} />
              <Route path='faq' element={<Faqs/>} />
              <Route path='band' element={<Blank/>} />
              <Route path='logon' element={<Logon setCurrentUser={setCurrentUser} />} />
              <Route path='emails' element={<EmailTable currentUser={currentUser} />} />
              <Route path='*' element={<PageNotFound/>} />
            </Route>
          </Routes>
          <Footer/>
        </Grid>
    </ChakraProvider>
  );
*/
